.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: rgba(255, 255, 255, 0.87);
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

// override the iframe that's overlaying the entire page for whatever reason
iframe {
	position: aboslute !important;
	top: 100% !important;
}
#mainLogo {
	width: 150px;
}
.navbar .container {
	max-width: 1450px;
}
a.navbar-brand {
	padding-left: 0;
	margin-left: 0;
}
.a2p-err {
	opacity: 0.5;
	margin-left: 0.5rem;
	font-style: italic;
}
// .a2p{
//   overflow-y:hidden;
//   display:flex;
//   height:100%;
//   width:100%;
//   text-align:left;
.record-details__inner {
	height: 100%;
}
.record-details__left {
	padding: 1.5rem 1rem;
}
.record-details__right {
	height: 100%;
	overflow-y: auto;
}
.record-overrides {
	position: fixed;
	background: white;
	bottom: 1rem;
	right: 10rem;
	padding: 1rem;
	width: 400px;
	box-shadow: 0 0px 7px rgba(0, 0, 0, 0.35);
	border-radius: 12px;
	max-height: 90vh;
	overflow-y: auto;
	z-index: 9999;
}
.record-overrides > div {
	display: flex;
	flex-direction: column;
	> div {
		margin-bottom: 0.75rem;
	}
}
.status-context {
	overflow-wrap: anywhere;
}
h5 {
	margin: 0 0.25rem 0;
	font-size: 1.25rem;
	font-weight: bold;
}
h6 {
	margin: 0 0.25rem;
}
* {
	box-sizing: border-box;
}
> * {
	padding: 1rem;
	min-width: 0;
}
> *:not(:first-child) {
	padding-left: 0.5rem;
}
> *:not(:last-child) {
	padding-right: 0.5rem;
}
// }
.last-updated-on {
	font-style: italic;
	opacity: 0.74;
}

.app-left {
	width: 260px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	height: 100vh;
	// p{
	//   color:white;
	// }
}
.status-selector {
	cursor: pointer;
	&s__container {
		display: flex;
		font-size: 12px;
		flex-grow: 0;
	}
	&:not(:first-child) {
		margin-left: 0.25rem;
	}
	&.is-active {
		color: green;
	}
}
.record-selector {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
	.css-cveggr-MuiListItemIcon-root {
		min-width: 32px;
	}
	&s__container {
		min-height: 0;
		overflow-y: auto;
	}
}
.active-record {
	flex-grow: 1;
	&__objects {
		flex-shrink: 0;
	}
}
.is-active-bg {
	color: green;
	font-weight: bold;
}
.active-record {
	.attribute {
		display: flex;
		.field-label {
			margin-right: 0.5rem;
			font-weight: bold;
			opacity: 0.87;
			flex-grow: 0;
			flex-shrink: 0;
		}
		.field-value {
			flex-grow: 1;
			min-width: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&.stacked {
			display: block;
			line-height: 1.2;
			margin-bottom: 0.25rem;
		}
	}
}
.record-details {
	&__inner {
		width: 100%;
		display: flex;
	}
	&__left {
		width: 300px;
		flex-grow: 0;
		flex-shrink: 0;
	}
	&__right {
		width: 70%;
		min-width: 0;
		padding-left: 1rem;
		pre {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
.refresh-btn {
	position: fixed;
	bottom: 0;
	left: 0;
	background: blue;
	color: white;
	padding: 1rem;
}
.status-context {
	background: #ca8029;
	padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	margin-bottom: 1rem;
	a {
		color: #3c4144;
	}
}
.record-overrides {
	margin-bottom: 1rem;
	button {
		background: #00a3ed;
		margin-top: 0.5rem;
	}
	> div {
		margin-bottom: 0.25rem;
	}
}
.css-tlelie-MuiListItemText-root {
	overflow: hidden;
}

// .record-details__right{
//   input{
//     color:white !important;
//     &::placeholder{
//       color:white;
//     }
//   }
//   input::placeholder{
//     color:white;
//   }
// }
